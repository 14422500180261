import {
    LOADING,
    ADD_Studients_SUCCESS,
    ADD_Studients_FAIL,
    } from "../constants/StudentsConstant";
    
    const initialState = {
      loading: false,
      errors: {},
      studients: {},
    };
    export const studientReducer = (state = initialState, { type, payload }) => {
        switch (type) {
          case LOADING:
            return { ...state, loading: true };
          case ADD_Studients_SUCCESS:
            return { ...state, loading: false, errors: null };
          case ADD_Studients_FAIL:
            return { ...state, errors: payload, loading: false };
         
          default:
            return state;
        }
      };