import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { store } from "./Redux/store";
import { Provider } from "react-redux";

const Haevy = lazy(()=> import ("./App"))

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense fallback={<div className="center-load"> <div className="ring"></div> <span className="loading">Loading ... </span></div>}>
      <BrowserRouter>
        <Provider store={store}>
          <Haevy />
        </Provider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);

reportWebVitals();
