import {
    LOADING,
    ADD_NewStudients_SUCCESS,
    ADD_NewStudients_FAIL
    } from "../constants/PlatformConstant";
    
    const initialState = {
      loading: false,
      errors: {},
      platform:{},
    };
    export const platformReducer = (state = initialState, { type, payload }) => {
        switch (type) {
          case LOADING:
            return { ...state, loading: true};
          case ADD_NewStudients_SUCCESS:
            return { ...state, loading: false, errors: null};
          case ADD_NewStudients_FAIL:
            return { ...state, errors: payload, loading: false };

          default:
            return state;
        }
      };