import {
    LOADING,
    ADD_SAT_SUCCESS,
    ADD_SAT_FAIL
    } from "../constants/SatConstant";
    
    const initialState = {
      loading: false,
      errors: {},
      sat:{},
      satList:[]
    };
    export const satReducer = (state = initialState, { type, payload }) => {
        switch (type) {
          case LOADING:
            return { ...state, loading: true};
          case ADD_SAT_SUCCESS:
            return { ...state, loading: false, errors: null};
          case ADD_SAT_FAIL:
            return { ...state, errors: payload, loading: false };

          default:
            return state;
        }
      };